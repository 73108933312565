// src/utils/s3Utils.ts
import { ListObjectsV2Command } from '@aws-sdk/client-s3';
import { s3Client } from './s3Client';
import { bucketName, stage } from './constants';

export const listOpenApiFiles = async () => {
  const command = new ListObjectsV2Command({
    Bucket: bucketName,
    Prefix: '',
  });

  const response = await s3Client.send(command);

  const content = response.Contents?.filter((file) => file.Key?.endsWith('.json')) || [];

  return content;
};

export const getOpenApiDoc = async (key: string) => {
  return `https://${bucketName}.s3.eu-central-1.amazonaws.com/${key}`;
};
