<!-- src/components/SwaggerUI.vue -->
<template>
  <div id="swagger-ui"></div>
</template>

<script lang="ts">
import { defineComponent, onMounted, watch } from 'vue';
import SwaggerUI from 'swagger-ui-dist/swagger-ui-es-bundle';
import { getOpenApiDoc } from '../utils/s3Utils';

export default defineComponent({
  name: 'SwaggerUIComponent',
  props: {
    fileKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const renderSwaggerUI = async () => {
      console.log('render Swagger UI', props.fileKey);

      const url = await getOpenApiDoc(props.fileKey);
      if (url) {
        SwaggerUI({
          url,
          dom_id: '#swagger-ui',
          defaultModelsExpandDepth: -1,
          plugins: [SwaggerUI.plugins.DownloadUrl],
        });
      }
    };

    onMounted(() => {
      renderSwaggerUI();
    });

    watch(
      () => props.fileKey,
      () => {
        renderSwaggerUI();
      }
    );

    return {};
  },
});
</script>

<style scoped>
@import '~swagger-ui-dist/swagger-ui.css';
</style>
