export const stage = process.env.VUE_APP_STAGE || 'ekadar';
if (!stage) {
  throw new Error('stage is undefined');
}

export const identityPoolId = process.env.VUE_APP_POOL_ID || 'eu-central-1:ba1263b5-0e03-4e49-ae84-141e3a1c388c';
if (!identityPoolId) {
  throw new Error('identityPoolId is undefined');
}

export const bucketName = `${stage}-api-docs-json-ei-tonies`;
